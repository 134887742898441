import SmoothScroll from "smooth-scroll";

var scroll = new SmoothScroll('a[href*="#"]', {
  speed: 500,
  speedAsDuration: true,
});

var yShownBtnBackToTop = 0;

document.addEventListener("scroll", function () {
  let scrollPosition = window.pageYOffset;
  const backToTopBtn = document.getElementById("back-to-top");

  var observer = new IntersectionObserver(
    function (entries) {
      if (entries[0].isIntersecting === true) {
        yShownBtnBackToTop = scrollPosition;
      }
    },
    { threshold: [1] }
  );

  observer.observe(document.querySelector("#show-back-to-top"));

  if (scrollPosition < yShownBtnBackToTop) {
    backToTopBtn.classList.remove("d-flex");
    backToTopBtn.classList.add("d-none");
  } else {
    backToTopBtn.classList.remove("d-none");
    backToTopBtn.classList.add("d-flex");
  }
});

var elements = document.getElementsByClassName("collapsible");

for (const element of elements) {
  element.addEventListener("click", (e) => {
    var ht = document.getElementsByClassName("treatment-item__content");

    for (const h of ht) {
      if (!h.classList.contains(e.target.id)) {
        h.style.maxHeight = null;
        h.style.opacity = "0";
        h.style.padding = "0";
        h.style.display = "none";
        h.style.borderTop = "unset";
        h.style.borderBottom = "unset";
      }
    }

    for (const e of elements) {
      e.classList.remove("treatment-item__title--lima");
      e.classList.add("treatment-item__title--gallery");
    }

    var clEl = document.getElementsByClassName(e.target.id);
    var selectedEl = document.getElementById(e.target.id);

    if (clEl[0].style.maxHeight) {
      clEl[0].style.maxHeight = null;
      clEl[0].style.opacity = "0";
      clEl[0].style.padding = "0";
      clEl[0].style.borderTop = "unset";
      clEl[0].style.borderBottom = "unset";
      selectedEl.classList.remove("treatment-item__title--lima");
      selectedEl.classList.add("treatment-item__title--gallery");
    } else {
      clEl[0].style.display = "block";
      clEl[0].style.opacity = "1";
      clEl[0].style.padding = "16px";
      clEl[0].style.borderTop = "2px solid #8dc21f";
      clEl[0].style.borderBottom = "2px solid #8dc21f";
      clEl[0].style.maxHeight = clEl[0].scrollHeight + 16 + "px";
      selectedEl.classList.remove("treatment-item__title--gallery");
      selectedEl.classList.add("treatment-item__title--lima");
    }
  });
}

// メーラーの起動：蔵王整骨院
const mailSeikotsu = document.querySelector(".mail-seikotsu");

if (mailSeikotsu != null) {
  mailSeikotsu.addEventListener("click", () => {
    function converter(M) {
      var str = "",
        str_as = "";
      for (var i = 0; i < M.length; i++) {
        str_as = M.charCodeAt(i);
        str += String.fromCharCode(str_as + 1);
      }
      return str;
    }

    function mail_to(k_1, k_2) {
      eval(
        String.fromCharCode(
          108,
          111,
          99,
          97,
          116,
          105,
          111,
          110,
          46,
          104,
          114,
          101,
          102,
          32,
          61,
          32,
          39,
          109,
          97,
          105,
          108,
          116,
          111,
          58
        ) +
          escape(k_1) +
          converter(
            String.fromCharCode(
              121,
              96,
              110,
              44,
              114,
              100,
              104,
              106,
              110,
              115,
              114,
              116,
              104,
              109,
              63,
              114,
              103,
              104,
              109,
              114,
              100,
              104,
              99,
              110,
              45,
              105,
              111,
              62,
              114,
              116,
              97,
              105,
              100,
              98,
              115,
              60
            )
          ) +
          escape(k_2) +
          "'"
      );
    }

    mail_to("", "");
  });
}

// メーラーの起動：キング・D・サービス
const mailDayservice = document.querySelector(".mail-dayservice");

if (mailDayservice != null) {
  mailDayservice.addEventListener("click", () => {
    function converter(M) {
      var str = "",
        str_as = "";
      for (var i = 0; i < M.length; i++) {
        str_as = M.charCodeAt(i);
        str += String.fromCharCode(str_as + 1);
      }
      return str;
    }

    function mail_to(k_1, k_2) {
      eval(
        String.fromCharCode(
          108,
          111,
          99,
          97,
          116,
          105,
          111,
          110,
          46,
          104,
          114,
          101,
          102,
          32,
          61,
          32,
          39,
          109,
          97,
          105,
          108,
          116,
          111,
          58
        ) +
          escape(k_1) +
          converter(
            String.fromCharCode(
              106,
              45,
              99,
              45,
              114,
              63,
              114,
              103,
              104,
              109,
              114,
              100,
              104,
              99,
              110,
              45,
              105,
              111,
              62,
              114,
              116,
              97,
              105,
              100,
              98,
              115,
              60
            )
          ) +
          escape(k_2) +
          "'"
      );
    }

    mail_to("", "");
  });
}

const mailContact = document.querySelector(".mail-contact");
if (mailContact != null) {
  mailContact.addEventListener("click", () => {
    function converter(M) {
      var str = "",
        str_as = "";
      for (var i = 0; i < M.length; i++) {
        str_as = M.charCodeAt(i);
        str += String.fromCharCode(str_as + 1);
      }
      return str;
    }

    function mail_to(k_1, k_2) {
      eval(
        String.fromCharCode(
          108,
          111,
          99,
          97,
          116,
          105,
          111,
          110,
          46,
          104,
          114,
          101,
          102,
          32,
          61,
          32,
          39,
          109,
          97,
          105,
          108,
          116,
          111,
          58
        ) +
          escape(k_1) +
          converter(
            String.fromCharCode(
              104,
              109,
              101,
              110,
              63,
              114,
              103,
              104,
              109,
              114,
              100,
              104,
              99,
              110,
              45,
              105,
              111,
              62,
              114,
              116,
              97,
              105,
              100,
              98,
              115,
              60
            )
          ) +
          escape(k_2) +
          "'"
      );
    }

    mail_to("", "");
  });
}
